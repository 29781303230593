import {SubMainContext, SubPlusContext} from "../services/entity/ActionContext";
import UserProfile from "../services/entity/UserProfile";
import AuthService from "../services/AuthService";
import DataService from "../services/DataService";
import TextUtilService from "../services/TextUtilService";
import BrandingService from "../../../branding/BrandingService";
import StorageService from "../services/StorageService";
import ModalMessageService from "../services/ModalMessageService";
import {IDeferred, IHttpResponse} from "angular";

export default class ProfileController {

    static $inject = [
        '$scope', '$location', '$route', '$controller', '$timeout', '$q',
        'AuthService', 'DataService', 'TextUtilService', 'BrandingService', 'StorageService', 'ModalMessageService'
    ];

    constructor(
        protected readonly $scope: any,
        protected readonly $location: ng.ILocationService,
        protected readonly $route: ng.route.IRouteService,
        protected readonly $controller: ng.IControllerService,
        protected readonly $timeout : ng.ITimeoutService,
        protected readonly $q : ng.IQService,
        protected readonly authService : AuthService,
        protected readonly dataService : DataService,
        protected readonly textUtilService : TextUtilService,
        protected readonly brandingService : BrandingService,
        protected readonly storageService : StorageService,
        protected readonly modalMessageService: ModalMessageService,
    ) {

        angular.extend(this, $controller('BaseController', {$scope: $scope}));

        function createUpdatedContent() {
            return {
                notEmpty() {
                    return Object.entries(this).filter(e => ((typeof e[1]) !== 'function')).length !== 0;
                }
            };
        }

        $scope.updatedContent = createUpdatedContent();

        $scope.avatarSrc = "/media/avatar";

        $scope.uploadAvatar = function(files: Array<Blob>) {
            console.info("uploadAvatar");
            if (!!files && !!files[0]) {
                const fileReader = new FileReader();
                const formData = new FormData();

                fileReader.addEventListener("load", function (e) {
                    const imgContent = e.target.result;
                    const imgBlob = new Blob([ imgContent ]);
                    // formData.append("file", files[0]);
                    formData.append("file", imgBlob, "avatar");
                    console.info("e");
                    console.info(e);
                    $scope.$apply(() => {
                        $scope.avatarSrc = imgContent;
                        // $scope.updatedContent = true;
                        $scope.updatedContent.avatarForm = formData;

                        console.info("avatarForm ready");
                        console.info(formData);
                    });
                });
                fileReader.readAsDataURL(files[0]);
            } else {
                $timeout(function() {
                    const fileInput = document.querySelector('input[name=avatar]');
                    angular.element(fileInput).triggerHandler('click');
                }, 0);
            }
        };

        $scope.$watch("user", function(user: UserProfile, _: UserProfile) {
            if (!!user) {
                const msisdn = user.msisdn;
                $scope.authorized = !!msisdn;
                $scope.phoneNumber = !!msisdn ? msisdn : "n/a";

                $scope.userName = !!user.nickName ? user.nickName : "";
                $scope.avatarSrc = "/media/avatar" + (!!user.profileId ? `/${user.profileId}` : "");

                $scope.$on("fileProgress", (e, progress: ProgressEvent) => {
                    $scope.progress = progress.loaded / progress.total;
                });

                $scope.$watch("userName", (userName: string, oldValue: string) => {
                    if (userName !== oldValue) {
                        // $scope.updatedContent = true;
                        $scope.updatedContent.userName = userName;
                    }
                });

                $scope.subMainAvailable = !user.paymentProfile.subMain;
                $scope.subPlusAvailable = brandingService.plusAvailable && user.paymentProfile.plusAvailable && !user.paymentProfile.subPlus;

                $scope.subMain = !!user.paymentProfile.subMain;
                $scope.subPlus = !!user.paymentProfile.subPlus;
            }
        });

        $scope.doSave = ($event: Event) => {
            $event?.preventDefault();
            $event?.stopPropagation();

            const nickNameDefer: IDeferred<boolean> = $q.defer();
            const avatarDefer: IDeferred<boolean> = $q.defer();
            const csrf = !!$scope.user ? $scope.user.csrf : null;

            $q.all([nickNameDefer.promise, avatarDefer.promise]).then((results) => {
                console.info(results);
                if (!!results[0] && !!results[1]) {
                        $scope.updatedContent = createUpdatedContent();
                        // $route.reload();
                        authService.updateUserData(null);
                }
            });


            const userName = $scope.updatedContent.userName;
            if (!!userName) {
                const data = new Blob([userName], {type: 'text/plain'});
                dataService.sendPost(`/profile/name`, data, csrf, 'text')
                    .then((obtainResponse: IHttpResponse<string>) => {
                        let errorMessage = null;
                        switch (obtainResponse.data) {
                            case "":
                                nickNameDefer.resolve(true);
                                break;
                            case "NICKNAME_EMPTY":
                                // "Имя не может быть пустым";
                                errorMessage = textUtilService.localizedText('profile.name.error.NICKNAME_EMPTY');
                                nickNameDefer.resolve(false);
                                break;
                            case "NICKNAME_INCORRECT":
                                // "Некорректное имя: укажите имя от 3 до 64 символов";
                                errorMessage = textUtilService.localizedText('profile.name.error.NICKNAME_INCORRECT');
                                nickNameDefer.resolve(false);
                                break;
                            case "NICKNAME_NON_UNIQUE":
                                // "Данное имя уже зарегистрировано за другим пользователем. Попробуйте выбрать другое.";
                                errorMessage = textUtilService.localizedText('profile.name.error.NICKNAME_NON_UNIQUE');
                                nickNameDefer.resolve(false);
                                break;
                            case "NICKNAME_SWEAR_WORD":
                                // "Данное имя недопустимо. Попробуйте выбрать другое.";
                                errorMessage = textUtilService.localizedText('profile.name.error.NICKNAME_SWEAR_WORD');
                                nickNameDefer.resolve(false);
                                break;
                            case "ERROR":
                                // "Ошибка при сохранении имени";
                                errorMessage = textUtilService.localizedText('profile.name.error.ERROR');
                                nickNameDefer.resolve(false);
                                break;
                            default:
                                // `Неизвестный ответ: ${obtainResponse.data}`;
                                errorMessage = textUtilService.localizedText('profile.error.response', {response: obtainResponse.data});
                                nickNameDefer.resolve(false);
                                break;
                        }

                        if (!!errorMessage) {
                            const errTitle = textUtilService.localizedText("core.error");
                            modalMessageService.alert(errTitle, errorMessage);
                        }
                    });

            } else {
                nickNameDefer.resolve(true);
            }

            if (!!$scope.updatedContent.avatarForm) {
                //todo: post
                console.info("post");
                console.info($scope.updatedContent.avatarForm);
                dataService.sendPost(`/media/avatar`, $scope.updatedContent.avatarForm, csrf, '')
                    .then((obtainResponse: IHttpResponse<ArrayBuffer>) => {
                        console.info(obtainResponse);
                        avatarDefer.resolve(true);
                    });

                /*$http.post(`/media/avatar`, $scope.updatedContent.avatarForm, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': undefined,
                        'X-CSRF-TOKEN': csrf
                    },
                    transformRequest: angular.identity
                }).success( () => {
                    avatarDefer.resolve(true);
                }).error( () => {
                    avatarDefer.resolve(false);
                });*/
            } else {
                avatarDefer.resolve(false);
            }

        };

        $scope.doLogout = ($event: Event) => {
            $event?.preventDefault();
            $event?.stopPropagation();
            const csrf = !!$scope.user ? $scope.user.csrf : null;
            dataService.logout('/logout', csrf, <JSON><unknown>{
                returnUrl: window.location.href
            })
                .then(function (handleResponse) {
                    console.log("location: " + location);
                    if (handleResponse.status === 200 || handleResponse.status === 401) {
                        storageService.remove("authHeader"); // AUTH_HEADER_STORAGE_KEY
                        $scope.go('/'); //// const savedAuth = this.StorageService.get(AUTH_HEADER_STORAGE_KEY);

                    // фактически, не вызывается – xmlHttpRequest сам идёт по redirect-url'у
                    } else if (handleResponse.status === 302) {
                        const location = handleResponse.headers('location');
                        if (!!location) {
                            window.location.replace(location);
                        } else {
                            $scope.go('/');
                        }
                    } else {
                        // `Невозможно выполнить выход: ${handleResponse.statusText}`
                        const errTitle = textUtilService.localizedText("core.error");
                        modalMessageService.alert(errTitle, textUtilService.localizedText("profile.error.logout", {"cause": handleResponse.statusText}));
                    }
                }).catch(function (errorResponse) {
                    console.warn(errorResponse);
                    const errTitle = textUtilService.localizedText("core.error");
                    // `Невозможно выполнить выход: ${errorResponse.statusText}`
                    modalMessageService.alert(errTitle, textUtilService.localizedText("profile.error.logout", {"cause": errorResponse.statusText}));
                });
        };

        $scope.subscribeMain = () => {
            authService.submitActionDefault(new SubMainContext());
        };

        $scope.subscribePlus = () => {
            authService.submitActionDefault(new SubPlusContext());
        };
    }
}
