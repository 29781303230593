import ModalMessageService from "../services/ModalMessageService";
import DataService from "../services/DataService";
import PlayerService from "../services/PlayerService";
import TextUtilService from "../services/TextUtilService";

enum SongHistoryEventType {
    PLAY= "PLAY",
    PERF= "PERF",
    DUET= "DUET",
    BATL = "BATL"
}

class SongHistoryEvent {
    public readonly id: number;
    public readonly userId: number;
    public readonly songId: number;
    public readonly event: SongHistoryEventType;
    public readonly eventMeta: string;
    public readonly score: number;
    public readonly time: Date;

    constructor(id: number, userId: number, songId: number, event: SongHistoryEventType, eventMeta: string, score: number, time: Date) {
        this.id = id;
        this.userId = userId;
        this.songId = songId;
        this.event = event;
        this.eventMeta = eventMeta;
        this.score = score;
        this.time = time;
    }
}

export default class HistoryController {

    static $inject = [
        '$scope', '$controller', '$compile',
        'DataService', 'PlayerService', 'TextUtilService', 'ModalMessageService'
    ];

    constructor(
        $scope,
        $controller,
        $compile,
        dataService: DataService,
        playerService: PlayerService,
        textUtilService: TextUtilService,
        modalMessageService: ModalMessageService
    ) {

        angular.extend(this, $controller('BaseController', {$scope: $scope}));

        $scope.items = [];// == null ? [] : songs;
        $scope.songs = [];// == null ? [] : songs;
        $scope.filter = {
            label: textUtilService.localizedText("performance.history.filter.all"),
            event: null,
            comp: (actual, expected) => {
                if (expected === null) {
                    return true;
                } else {
                    return angular.equals(actual, expected);
                }
            }
        };

        $scope.filterModeAll = () => {
            $scope.filter.label = textUtilService.localizedText("performance.history.filter.all");
            $scope.filter.event = null;
            modalMessageService.hideAll();
        };
        
        $scope.filterModeRec = () => {
            $scope.filter.label = textUtilService.localizedText("performance.history.filter.rec"); //'Только записи';
            $scope.filter.event = 'PERF';
            modalMessageService.hideAll();
        };

        const load = () => {
            dataService.dataHttp<Array<SongHistoryEvent>>('/history/load').then(function (response) {
                $scope.items = response.data;

                // let query = "";
                let songs = [];
                response.data.forEach(function (item) {
                    // query = query + (query.length > 0 ? ',' : '') + item.songId;
                    songs.push(item.songId);
                });

                dataService.songs(songs).then(function (response) {
                    for (let i = 0; i < response.data.length; i++) {
                        $scope.songs[response.data[i].id] = response.data[i];
                    }
                }, function (response) {
                    const errTitle = textUtilService.localizedText("core.error");
                    modalMessageService.alert(errTitle, textUtilService.localizedText("performance.error.song")); // "Невозможно загрузить песни."
                    $scope.error = true;
                });
            }, function () {
                const errTitle = textUtilService.localizedText("core.error");
                modalMessageService.alert(errTitle, textUtilService.localizedText("performance.error.data")); // "Невозможно загрузить данные."
                $scope.error = true;
            });
        };

        load();

        $scope.doFilter = () => {
            const linkFn = $compile("" +
                "<button-row class='history-mode' state='all' title='performance.history.filter.all' action='filterModeAll()'></button-row>\n" +
                "<button-row class='history-mode' state='rec' title='performance.history.filter.rec' action='filterModeRec()'></button-row>"
            );
            const html = linkFn($scope);

            modalMessageService.dialogExt({
                className: 'modal-auth',
                title: textUtilService.localizedText("performance.history.filter.title"),
                message: html
            });
        };

        $scope.doRemove = (item) => {
            modalMessageService.dialogExt({
                title: textUtilService.localizedText("performance.history.remove.title"), // "Удаление записи"
                message: textUtilService.localizedText("performance.history.remove.message"), // "Вы точно хотите удалить запись исполения?"
                buttons: {
                    success: {
                        label: textUtilService.localizedText("performance.history.remove.button.success"), // "Продолжить"
                        className: "btn-success",
                        callback: () => {
                            const csrf = !!$scope.user ? $scope.user.csrf : null;
                            dataService.sendPost(`/history/delete/${item.id}/`, null, csrf, '')
                                .then((handleResponse) => {
                                    const index = $scope.items.indexOf(item);
                                    if (index > -1) {
                                        $scope.items.splice(index, 1);
                                    }
                                });
                        }
                    },
                    cancel: {
                        label: textUtilService.localizedText("performance.history.remove.button.cancel"), // "Отмена"
                        className: "btn-primary",
                        callback: function () {
                        }
                    }
                }
            });
        };

        $scope.doPlay = (item) => {
            if (item.event === 'PLAY') {
                const songId = item.songId;
                const song = $scope.songs[songId];
                if (!!song) {
                    $scope.playAction(song, 'performance.repeat.title.repeat', '/history', null, 'playback');
                }

            } else if (item.event === 'PERF') {
                const perfId = item.eventMeta;
                $scope.go(`/playback/${perfId}`);
            }
        };
    }
}
