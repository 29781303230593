import modalRecordTemplate from "./ModalRecord.html";
import {ExecutionContext, SongContext, SubMainContext, TrialTransaction} from "../../services/entity/ActionContext";
import {SongAction} from "../../services/entity/SongAction";
import Song from "../../entity/Song";
import {karaoke} from "../../karaoke";
import IRootScopeService = karaoke.IRootScopeService;
import AuthService from "../../services/AuthService";
import TrialService from "../../services/TrialService";
import DataService, {HttpParam} from "../../services/DataService";
import PlayerService, {InternalPlayerData} from "../../services/PlayerService";
import SubtitleService from "../../services/SubtitleService";
import TextUtilService from "../../services/TextUtilService";
import StorageService from "../../services/StorageService";
import DecoratorService from "../../services/DecoratorService";
import BrandingService from "../../../../branding/BrandingService";
import PerformanceService from "./PerformanceService";
import SettingsService from "../../services/SettingsService";
import EventService from "../../services/EventService";
import ModalMessageService from "../../services/ModalMessageService";
import BaseController from "../../controllers/BaseController";

export default class JukeboxController extends BaseController {

    static $inject = [
        '$scope', '$location', '$route', '$controller', '$rootScope', '$timeout', '$window', '$compile', '$routeParams', '$q',
        'AuthService', 'TrialService', 'DataService', 'PlayerService', 'SubtitleService', 'TextUtilService', 'StorageService',
        'DecoratorService', 'BrandingService', 'PerformanceService', 'SettingsService', 'EventService', 'ModalMessageService'
    ];

    /**
     *
     * @param $scope
     * @param $location
     * @param $route
     * @param $controller
     * @param $rootScope
     * @param $timeout
     * @param $window
     * @param $compile
     * @param $routeParams
     * @param $q
     * @param authService
     * @param trialService
     * @param dataService
     * @param playerService
     * @param subtitleService
     * @param textUtilService
     * @param storageService
     * @param decoratorService
     * @param brandingService
     * @param performanceService
     * @param settingsService
     * @param eventService
     * @param modalMessageService
     */
    constructor(
        private readonly $scope,
        private readonly $location: ng.ILocationService,
        private readonly $route: ng.route.IRouteService,
        private readonly $controller: ng.IControllerService,
        private readonly $rootScope: IRootScopeService,
        private readonly $timeout: ng.ITimeoutService,
        private readonly $window: ng.IWindowService,
        private readonly $compile: ng.ICompileService,
        private readonly $routeParams: ng.route.IRouteParamsService,
        private readonly $q: ng.IQService,

        private readonly authService: AuthService,
        private readonly trialService: TrialService,
        private readonly dataService: DataService,
        private readonly playerService: PlayerService,

        private readonly subtitleService: SubtitleService,
        private readonly textUtilService: TextUtilService,
        private readonly storageService: StorageService,

        private readonly decoratorService: DecoratorService,
        private readonly brandingService: BrandingService,
        private readonly performanceService: PerformanceService,
        private readonly settingsService: SettingsService,
        private readonly eventService: EventService,
        private readonly modalMessageService: ModalMessageService
    ) {
        super($scope, $rootScope, $route, authService, playerService, textUtilService)

        const paramSongId = $routeParams.songId;
        if (!!paramSongId) {
            console.info(`paramSongId ${paramSongId}`);
            this.dataService.songs([paramSongId]).then((result) => {
                const song = result.data[0];
                if (!!song) {
                    this.$scope.attemptAction(new SongContext(song, SongAction.PERFORM)).then((context: ExecutionContext) => {
                        if (context instanceof TrialTransaction) {
                            if (!!context.rollbackAction) {
                                context.rollbackAction();
                            }
                        }
                        this.playerService.load(song.title, '/', [song], song, 'playback-direct');
                        this.init();
                    }).catch(reason => {
                        this.playerService.clear();
                        this.init()
                    })
                } else {
                    this.playerService.clear();
                    this.init();
                }
            }).catch((() => this.init()))
        } else {
            console.info("no paramSongId");
            this.init()
        }
    }


    private init() {
        /**
         * Отмечаем как проигранную после того как человек проиграл несколько секунд, а не при показе плеера
         * @type {boolean}
         */
        let markOnNotifyMode = true;

        let inited = false;

        /**
         * Функция, отмечающая что trial был использован
         */
        let notifyPlayTrialAction = null;

        /**
         * Обработка ошибок
         * @param log сообщение для лога
         * @param text сообщение для пользователя
         * @param detailObject объект для логирования
         */
        const handleError = (log: string, text: string, detailObject: any = null) => {
            console.info(log);
            if (!!detailObject) {
                console.info(detailObject);
            }
            const errTitle = this.textUtilService.localizedText("core.error");
            this.modalMessageService.alert(errTitle, text);
        };

        const getSong = () => {
            const songOrContext = (
                !!(this.playerService.playerData) &&
                !!(this.playerService.playerData.playlist) &&
                !!(this.playerService.playerData.playlist.trackList)
            ) ? this.playerService.playerData.playlist.trackList[0] : null;
            if (songOrContext instanceof Song) {
                // console.info("test song in playlist: " + JSON.stringify(songOrContext));
                return songOrContext;
            } else if (songOrContext instanceof SongContext) {
                // console.info("test songContext in playlist: " + JSON.stringify(songOrContext));
                return songOrContext.song;
            } else {
                console.warn('unexpected Song or SongContext');
                console.warn(songOrContext);
                this.$location.path("/");
                this.$route.reload();
            }
        };

        // noinspection JSUnusedLocalSymbols
        this.$scope.$on("contextLoaded", (event, args) => {

            if (!inited) {
                //console.info('[P] init JukeboxController');

                // Если пользоветель не подписан и не имеет try-доступа, не даём доступ к плееру
                const song = getSong();

                // todo: если песня совпадает с той, что нужно исполнить, - предлагаем режим ответа

                if (song != null) {
                    // $scope.trial.terms = trialService.describe(song.source);

                    this.eventService.promoData(song).then(eventsIds => {
                        this.$scope.eventsIds = eventsIds.reduce((map, e) => (map[e] = true, map), {});
                    })
                }

                if (!this.authService.isSubscribedMain() && !this.trialService.isTryAllowed(new SongContext(song, SongAction.PERFORM))) {
                    console.info('[P] access denied');
                    this.$location.path("/");
                    this.$route.reload();
                }

                // инициализация, первоначальные данные могут быть не загружены
                if (this.playerService.playerData != null) {
                    // console.info('[P] no player, go to init');
                    this.$scope.init(this.playerService.playerData);
                } else {
                    //console.info('[P] player exist, start listen');
                    this.$scope.$on('player:initPlayerData', (event, playerData) => {
                        //console.info('[P] initPlayerData event');
                        this.$scope.init(playerData);
                    });
                }

                // при обновлении страницы в браузере, попытаемся оповестить о проигрывании
                this.$window.onbeforeunload = () => {
                    this.$scope.notifyPlay(true);
                };

                //runWatchDog($rootScope, $timeout);
            }
        });


        angular.extend(this, this.$controller('BaseController', {$scope: this.$scope}));

        //$scope.backurl = playerService.playerData.context.url;
        this.$scope.end = false;

        this.$scope.notified = null; // null - не должны оповещать, false - должны но ещё не оповестили, true - оповестили о проигрывании

        this.$scope.playerStatusData = {
            jPlayerStatus: null, // последний статус проигрывания
            trackPlayed: 0, // сколько проиграно
            trackLength: 0,  // общая длина трека

            /**
             * Обновление статуса трека
             * @param jPlayerStatus {PlayerStatus}
             */
            update: function(jPlayerStatus) {
                this.jPlayerStatus = jPlayerStatus;
                this.trackPlayed = (jPlayerStatus.currentTime !== 0) ? Math.floor(jPlayerStatus.currentTime) : this.trackPlayed;
                this.trackLength = (jPlayerStatus.duration !== 0) ? Math.floor(jPlayerStatus.duration) : this.trackLength;
            }
        };

        /**
         *
         * @param jPlayerStatus {PlayerStatus}
         */
        this.$scope.playerStatus = jPlayerStatus => {
            this.$scope.playerStatusData.update(jPlayerStatus);

            if (this.$scope.notified === null &&
                jPlayerStatus != null &&
                jPlayerStatus.paused === false &&
                jPlayerStatus.currentTime > 0) {
                // запомнили что должны оповестить, сделаем это при удобном случае
                //console.info("remember notify");
                this.$scope.notified = false;
            }

            /*if ($scope.notified === false
             && jPlayerStatus != null
             && jPlayerStatus.paused == true) {
             // поставили на паузу - пробуем оповестить
             $scope.notifyPlay();
             }*/
        };

        /**
         * получение параметров для оповещения на базе данных плеера
         * @param playerData PlayerService.playerData
         */
        function notifyParams(playerData: InternalPlayerData): HttpParam {
            var source = playerData.context.source;
            //console.info('source: ' + source);
            // для каталога можно передать идентификатор: 'catalog:1'
            const isCatalog = (!!source && source.indexOf('catalog') === 0);
            if (isCatalog) {
                const colonIndex = source.indexOf(':');
                if (colonIndex > 0) {
                    return {
                        from: 'catalog',
                        cat_id: source.substring(colonIndex + 1)
                    };
                }
            }

            return {
                from: source
            };
        }

        /**
         * Оповещение от плеера о том что прошло 0 секунд проигрывания трека
         */
        this.$scope.notifyPlay = (beacon = false) => {
            if (this.$scope.notified === false) {

                if (typeof notifyPlayTrialAction === "function") {
                    notifyPlayTrialAction();
                    notifyPlayTrialAction = null;
                }

                this.$scope.notified = true;
                const statusData = this.$scope.playerStatusData;
                const params: HttpParam = jQuery.extend(notifyParams(this.$scope.playerData), {
                    rend_dur: statusData.trackPlayed,
                    track_dur: statusData.trackLength
                });

                if (beacon) {
                    params['_csrf'] = !!this.$scope.user ? this.$scope.user.csrf : null;
                    this.dataService.sendBeacon(`/notify-song/${this.$scope.song.id}`, params);
                    return true;
                } else {
                    // noinspection JSUnusedLocalSymbols
                    return this.dataService.serviceHttp(`/notify-song/${this.$scope.song.id}`, {params: params})
                        .then(response => {
                            //console.info("Оповещение отправлено");
                            console.info("Notify play event");
                            console.info(response.data);
                            this.$scope.notifyPlayEvent = response.data;
                        }, response => {
                            //console.error("Оповещение не отправлено");
                            //console.error(response.data);
                            //console.error(response.status);
                            //console.error(response.headers);
                            //console.error(response.config);

                            this.$scope.notified = false;
                        });
                }

            } else {
                return false;
            }
        };

        this.$scope.$on('$destroy', () => {
            this.$window.onbeforeunload = undefined;
            this.$scope.notifyPlay();

            // todo: hacky - rollback if song wasn't played
            const context = this.$scope.attemptContext;
            if (context instanceof TrialTransaction) {
                if (!!context.rollbackAction) {
                    context.rollbackAction();
                }
            }
        });

        /**
         * Оповещение от плеера о том что проигрывание трека закончено
         */
        this.$scope.notifyFinish = () => {
            if (this.$scope.end !== true) {
                this.$scope.end = true;
                this.$scope.notifyPlay();
                // $scope.$broadcast('player:state', 'end');
                this.$scope.notifyState('end');
            }
        };

        this.$scope.notifyState = playerState => {
            this.$rootScope.$broadcast('player:state', playerState);
        };

        this.$scope.hasNext = () => this.playerService.hasNext();

        /**
         * Переход к следующему треку без окончания проигрывания
         */
        this.$scope.doNext = () => {

            //console.info("[P] doNext");

            this.$scope.notifyPlay();

            this.$timeout(() => {
                if (this.playerService.next()) {
                    // const nextSong = playerService.currentSong();
                    const nextContext = this.playerService.currentContext();
                    const nextSong = nextContext === null ? null : nextContext.song;
                    this.$scope.song = nextSong;
                    this.$scope.doPlay(nextContext);
                } else {
                    //console.info("Playlist has ended");
                    const backPath = this.playerService.playerData.context.url;
                    this.$location.path(backPath != null ? backPath : '/');
                }
            }, 300);
        };

        const recordAllowed = true; // BrandingService.operator !== 'TCell';

        // вместо сохранённого флага о попытке подписки, мы должны загрузить контекст, извлечь песню и передать её сюда
        /**
         *
         * @param context {SongContext}
         */
        this.$scope.doPlay = context => {
            if (!context || !context.song) {
                return;
            }
            const song = context.song;
            this.$scope.song = song;
            this.$scope.end = false;

            if (recordAllowed) {
                this.$scope.recState = this.playerService.asText(context.songAction);
            } else {
                this.$scope.recState = null;
            }

            // подготовка батлов для ответа
            this.performanceService.availableResponseBattles(song.id).then((btr) => {
                const isBattleResponse = !!context.battleId; // todo instanceOf ResponseBattleSongContext
                // console.info(`isBattleResponse: ${isBattleResponse}`);

                if (btr.length > 0) {
                    this.$scope.availableBattles = btr;
                    this.$scope.selectedBattleResponse = null;
                    if (isBattleResponse || (context.songAction === SongAction.PERFORM_BATTLE)) {
                        this.$scope.selectedBattleResponse = btr[0]; // по-умолчанию, первый
                        if (isBattleResponse) {
                            btr.forEach((b) => {
                                if (b.id === context.battleId) {
                                    this.$scope.selectedBattleResponse = b; // переопределяем батлом из параметра
                                }
                            });
                        }
                    }

                    // запросили ответ на баттл - включаем режим баттла
                    if (isBattleResponse) {
                        this.$scope.selectRecordModeBattle();
                    }

                    this.$scope.responseToBattle = (battle) => {
                        this.$scope.selectedBattleResponse = battle;
                        this.$scope.selectRecordModeBattle();
                        this.$route.reload();
                    };
                }
            });

            this.$scope.cancelPerformanceResponse = () => {
                this.$scope.selectedBattleResponse = null;
            };


            //console.info('[P] doPlay, markOnNotifyMode: ' + markOnNotifyMode);

            if (this.$scope.karaoke != null && this.$scope.karaoke.state != null) {
                this.$scope.karaoke.state = "loading";
            }

            this.$scope.attemptAction(context).then((context) => {
                // todo: hacky - rollback if song wasn't played
                this.$scope.attemptContext = context;

                if (!!context.trialUsed && !markOnNotifyMode) {
                    // режим, когда сразу отмечаем попытку использованной
                    context.submitAction();
                } else {
                    notifyPlayTrialAction = () => {
                        if (!!context.submitAction) {
                            context.submitAction();
                        }
                    };
                }

                const onFail = (logMessage) => {
                    console.info(logMessage);
                    if (!!context.trialUsed && markOnNotifyMode) {
                        context.rollbackAction();
                    }
                    this.$scope.processError("Невозможно получить субтитры, попробуйте позже.");
                };

                this.dataService.serviceHttp<string>(`/song/${song.id}.txt`)
                    .then(response => {
                        //console.info('[P] subtitle received');
                        ////console.info(response.data);

                        var contentType = response.headers('Content-Type');
                        if (contentType.lastIndexOf('text/plain', 0) === 0) {
                            const subData = this.subtitleService.parse(response.data);
                            //console.info('[P] subtitle parsed');
                            ////console.info(subData);

                            // todo: проверять корректность субтитров, например, data[0].data[0].timeEnd >= 0
                            const queryParams = "?" + $.param(notifyParams(this.$scope.playerData));
                            this.$scope.kardata = {
                                title: song.title,
                                cache: false,
                                songUrl: fileExtension => { // выбор расширения происходит в плеере
                                    return this.dataService.dataUrl(`/song/data/${song.id}.${fileExtension + queryParams}`);
                                },
                                subData: subData
                            };
                        } else {
                            onFail('[P] subtitle wrong Content-Type: ' + contentType);
                        }

                        //$scope.$apply();
                    }, response => {
                        onFail('[P] error: ' + response.status);
                    });

            }).catch((denyReason => {
                // отказ от авторизации / невозможность провести платёж...
                this.$location.path("/");
                this.$route.reload();
            }));
        };

        this.$scope.processError = message => {
            this.$scope.message = message;
            const errTitle = this.textUtilService.localizedText("core.error");
            this.modalMessageService.alert(errTitle, message, () => {
                this.$location.path("/");
                this.$route.reload();
            });
        };

        // инициализация плеера
        this.$scope.init = playerData => {
            // console.info('[P] init');
            // console.info(playerData);
            // console.trace('[P] init')
            this.$scope.playerData = playerData;

            // todo: понять что ответ на батл

            // todo: получить songcontext
            // const song = playerService.currentSong();
            const context = this.playerService.currentContext();
            const song = context === null ? null : context.song;
            //console.info('[P] current song: ');
            //console.info(song);
            if (song !== null) {
                this.$scope.doPlay(context);
            } else {
                //console.info("Start player without song, exit from player");
                // плейлист пуст
                this.$location.path("/");
                this.$route.reload();
            }
        };

        this.$scope.popupHelpMicrophone = () => {
            this.modalMessageService.dialogExt({
                className: 'modal-auth',
                title: this.textUtilService.localizedText("performance.record.mic.help.title"), // "Разрешение записи",
                message: this.textUtilService.localizedText("performance.record.mic.help.body"), // "Для предоставления доступа к микрофону, нажмите кнопку «Разрешить»."
                buttons: {
                    success: {
                        label: this.textUtilService.localizedText("core.close"),
                        className: "btn-close",
                        callback: () => {}
                    }
                }
            });
        };

        /**
         *
         * @param action {SongAction}
         */
        this.$scope.selectRecordMode = (action) => {
            console.info("selectRecordMode " + action);

            if (!!action) {
                if (this.$scope.recState === this.playerService.asText(action)) {
                    console.info(`already in state ${this.$scope.recState}`);
                    return;
                }
                // StorageService.set("RECORD_MODE", mode);
                this.playerService.setDefaultAction(action);

                const context = this.playerService.currentContext();
                this.playerService.updateContextAction(action);
                this.$scope.recState = this.playerService.asText(action);
                this.modalMessageService.hideAll();
                this.$route.reload();
            } else {
                const template = angular.element(modalRecordTemplate);
                const linkFn = this.$compile(template);
                const html = linkFn(this.$scope);

                this.modalMessageService.dialogExt({
                    className: 'modal-auth',
                    title: this.textUtilService.localizedText("performance.basic.select-title"),
                    message: html
                });
            }
        };

        this.$scope.selectRecordModeOn = () => {
            this.$scope.selectRecordMode(SongAction.PERFORM_RECORD); // 'on'
        };

        this.$scope.selectRecordModeOff = () => {
            this.$scope.selectRecordMode(SongAction.PERFORM); // 'off'
        };

        this.$scope.selectRecordModeBattle = () => {
            // todo проверить подписанность, если не подписан - модальное окно
            // todo: attemptPaidAction с параметрами
            this.authService.submitAction(new SongContext(getSong(), SongAction.PERFORM_BATTLE)).then((context) => {
                this.$scope.selectRecordMode(SongAction.PERFORM_BATTLE); // 'battle'
            }).catch(denyReason => {
                this.$scope.selectRecordMode(SongAction.PERFORM); // 'off'
            });
        };

        const action = this.playerService.getDefaultAction();
        this.$scope.recState = action == null ? null: this.playerService.asText(action);
        // $scope.recState = StorageService.get("RECORD_MODE");
        if (recordAllowed && !this.$scope.recState) {
            this.$scope.recState = "off";
        } else {
            switch (action) {
                // case 'battle-response':// todo
                case SongAction.PERFORM_BATTLE:
                    this.authService.submitAction(new SongContext(getSong(), SongAction.PERFORM_BATTLE)).then((context) => {
                        // do nothing - just check availability
                        // todo? rollback?

                        if (context instanceof TrialTransaction) { // todo: chek if working
                            context.rollbackAction();
                        }

                    }).catch(denyReason => {
                        this.$scope.selectRecordMode(SongAction.PERFORM); // 'off'
                    });
                    break;
            }
        }

        this.$scope.onRecDenied = () => {
            this.$scope.recordingDenied = true;
            this.$scope.recState = 'disabled';
        };

        this.$scope.onRecUnavailable = () => {
            this.$scope.recordingNotAvailable = true;
            this.$scope.recState = 'disabled';
        };

        this.$scope.onRecordReady = (defer) => {
            console.info("[JB] onRecordReady");
            this.$scope.doUploadRecord = () => defer.resolve(true);
        };

        /**
         * @param data данные записи
         * @param {number} songId идентификатор песни
         * @param {number} eventId идентификатор события прослушивания песни (опциоанльно)
         * @param {function} onSuccess callback на успешную обработку
         */
        this.$scope.onRecordSave = (data: Blob, songId, eventId, onSuccess) => {
            console.info("[JB] send data for song " + songId);
            console.info("[JB] send data of type " + data.type + " with size " + data.size);
            console.info("[JB] state " + this.$scope.recState);
            const csrf = !!this.$rootScope.user ? this.$rootScope.user.csrf : null;

            switch (this.$scope.recState) {
                case 'battle':
                    const respBattle = this.$scope.selectedBattleResponse;
                    console.info('respBattle');
                    console.info(respBattle);
                    let battleUrl;
                    if (!respBattle) {
                        // данная запись - инициация баттал
                        battleUrl = `/performance/battle/begin/?songId=${songId}`;
                    } else {
                        // данная запись - ответ на батл
                        const secret = this.$rootScope.user.inviteBattleCode;
                        battleUrl = `/performance/battle/response?battleId=${respBattle.id}&songId=${songId}&secret=${secret}`;
                    }
                    // todo analyze context ResponseBattleSongContext - нельзя, работаем через jukebox

                    this.dataService.sendPost(battleUrl, data, csrf, 'json')
                        .then(handleResponse => {
                            const data = handleResponse.data;
                            const status = handleResponse.status;

                            if (status === 200) {
                                console.info("[JB] OK send battle data " + songId);
                                onSuccess(); // подтверждение сохранения

                                const battleId = data.id;
                                this.$scope.doListenRecord = () => this.$scope.go(`/battle/${battleId}/`);

                                this.$scope.hideNext = true;
                                this.$scope.doUploadRecord = null;

                                /////////////////////////////////////////////
                                // переходим сразу же к информации о батле //
                                this.$scope.doListenRecord();
                            } else {
                                handleError(`[JB] ${status} send data ${songId}`, "Невозможно загрузить исполнение.");
                            }
                        }).catch((e) => handleError(`[JB] fail send data  ${songId}, ${e}`, "Невозможно загрузить исполнение.", e));
                    break;
                    
                case 'on':
                default:
                    const param = !!eventId ? `?eventId=${eventId}` : '';
                    const url = `/performance/handleAudioRecord/${songId}${param}`;
                    this.dataService.sendPost(url, data, csrf, 'json')
                        .then(handleResponse => {
                            const data = handleResponse.data;
                            const status = handleResponse.status;

                            if (status === 200) {
                                console.info("[JB] OK send record data " + songId);
                                onSuccess(); // подтверждение сохранения

                                const convertedRecordId = data.recordId;
                                const originalRecordId = data.originalRecordId;

                                this.$scope.doUploadRecord = undefined;
                                this.$scope.doListenRecord = () => this.$scope.go("/playback/" + convertedRecordId + "/");
                                this.$scope.scorePerDieci = Math.round(data.score * 10);
                                this.$scope.scorePerCento = Math.round(data.score * 100);

                                this.$scope.doDownloadOriginalRecord = this.settingsService.isRawDownloadAllowed() ? () =>
                                    this.performanceService.download(originalRecordId, !!this.$scope.user ? this.$scope.user.csrf : null) : undefined;
                            } else {
                                handleError(`[JB] ${status} send data ${songId}`, "Невозможно загрузить исполнение.");
                            }
                        }).catch((e) => handleError(`[JB] fail send data  ${songId}, ${e}`, "Невозможно загрузить исполнение.", e));
            }
        };

        this.$scope.$watch("playlist", (newValue, oldValue) => {
            this.$scope.promoMySongs = this.decoratorService.promoSongsItems(this.$scope.playlist.songs, (song) => this.$scope.playAction(song));
        });

        this.$scope.promoCatalogItems = this.decoratorService.promoCatalogContentItems((song) => this.$scope.playAction(song));
        this.$scope.promoNewItems = this.decoratorService.promoSongsPopularItems((song) => this.$scope.playAction(song));
        this.$scope.promoPopularItems = this.decoratorService.promoSongsNewItems((song) => this.$scope.playAction(song));

        // для кнопки выбора режима в плеере
        /*this.$scope.SongAction = SongAction;
        this.$scope.actionAvailable = (action: SongAction) => {
            return this.brandingService.plusAvailable && this.$scope.user.paymentProfile.isActionAvailable(action);
        };*/

        // this.$scope.availableBattles = this.performanceService.availableResponseBattles();
        // this.$scope.currentBattleResponse = null;

    }
}