import ModalMessageService from "../services/ModalMessageService";

export default class CatalogController {

    constructor($scope, $route, $location, $controller, DataService, DecoratorService, TextUtilService, ModalMessageService) {

        angular.extend(this, $controller('BaseController', {$scope: $scope}));

        // список каталогов
        if ($scope.catalogs == null) {
            DataService.requestCatalogs().then(function (data) {
                $scope.catalogs = data;
            }, function () {
                const errTitle = TextUtilService.localizedText("core.error");
                ModalMessageService.alert(errTitle, TextUtilService.localizedText("catalog.unable-to-load")); // "Невозможно загрузить данные альбомов."
                $scope.error = true;
            });
        }

        $scope.selectAlbum = function (id) {
            $scope.catalogs.forEach(function (catalog) {
                if (catalog.id === id) {
                    //$scope.title = cat.name; // заголовок того что будет загружено
                    // загружаем изображение альбома, невысокого разрешения (возможно, закешировано)
                    $scope.$parent.background.image($scope.catalogImageUrl(catalog.id, 'small'));
                }
            });
            $scope.go("/playlist/" + id + "/");
        };

        $scope.promoNewItems = DecoratorService.promoSongsPopularItems((song) => $scope.playAction(song));
        $scope.promoPopularItems = DecoratorService.promoSongsNewItems((song) => $scope.playAction(song));
    }
}

CatalogController.$inject = [
    '$scope', '$route', '$location', '$controller',
    'DataService', 'DecoratorService', 'TextUtilService', 'ModalMessageService'
];